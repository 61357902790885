
.page-container {
  .flags {
    width: 40px;
  }

  .box {
    padding: 20px;
    .title {
      font-size: 1rem;
      font-weight: 600;
    }
    .ant-card {
      margin-bottom: 20px;
    }
    .clock-box{
      margin: 10px 0 0 0;
      border: 0 none;
      text-align: center;
      font-size: 16px;
      font-family: Montserrat,Arial,Helvetica,sans-serif;
      .title{
        margin-top: 10px;
      }
      .con{
        font-size: 8rem;
      }
      .list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .list-item{
          background-color: #eee;
          padding: 8px 25px;
          text-align: right;
          margin-left: 10px;
          font-size: 14px;
          line-height: normal;
          cursor: pointer;
          .name{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 3px;
          }
        }
        .list-item:hover, .list-item.selected{
          background-color: #ddd;
        }
      }
    }
  }
  .box2 {
    padding: 20px 20px 20px 0;
  }

  .btn {
    overflow: hidden;
    white-space: normal;
    height: auto;
  }
  .country-list {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-top: 15px;
  }
  .columns {
    cursor: pointer;
    text-align: center;
  }
}
.country-info {
  padding: 30px 50px;
  // background-color: #fff;
  .flags {
    width: 40px;
    height: auto;
    margin-right: 20px;
    object-fit: contain;
  }
  .info {
    font-size: 16px;
    color: #212529;
    .name {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
      strong {
        font-weight: 400;
      }
    }
  }
}
.tb-hd {
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
}
.tb-title {
  border: 1px solid #dee2e6;
  border-top: 0 none;
  padding: 5px;
  background-color: #f8f9fa;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;

  .ant-col {
    font-size: 1rem;
  }
}
.tb-bd {
  font-size: 1rem;
  padding: 5px;
  border-bottom: 1px solid #dee2e6;
}
.tb-passed {
  opacity: 0.5;
  background: repeating-linear-gradient(
    45deg,
    #dee2e630,
    #dee2e630 10px,
    #ffffff30 10px,
    #ffffff30 20px
  );
}
.error-box {
  min-height: 900px;
  margin-top: 20px;
}
.pages {
  margin-top: 10px;
}
.c-list {
  font-size: 1rem;
  background-color: #fff;
  margin: 20px auto;
  .c-headers {
    justify-content: space-between;
    padding: 0.9375rem 1.5rem;
  }
  .name {
    font-size: 1rem;
  }
}
.map-wrap{
  text-align: center;
}
.map {
  width: 100%;
  height: 450px;
  background-color: transparent;
  text-align: center;
  margin-bottom: 15px;
}
.countries-wrap{
  width: 1200px;
  margin: 0 auto;
  .country-info{
    padding: 30px 0;
  }
}
.about{
  width: 1200px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  min-height: 800px;
  h1{
    font-weight: 500;
    font-size: 1.5rem;
  }
}

@media (max-width: 960px) {
  #root {
    .page-container {
      .box {
        padding: 0;
        .ant-card {
          margin-bottom: 0.625rem;
          .ant-card-body {
            padding: 0 0.625rem;
          }
        }
        .ant-card:last-child {
          padding: 0 0 0.625rem 0;
        }
        .clock-box{
          .con{
            font-size: 5rem;
          }
          .list{
            overflow-x: auto;
            width: 100%;
            justify-content: flex-start;
            white-space: nowrap;
          }
        }
      }
      .box2 {
        padding: 0;
      }
      .btn{
        text-align: left;
      }
      .country-list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    .country-info {
      padding: 1.25rem 0.625rem;
      flex-direction: column;
      align-items: center;
      .info {
        text-align: center;
        .name {
          font-size: 1.625rem;
          text-align: center;
        }
        .sub {
          font-size: 1rem;
          margin-top: 10px;
          font-weight: 100;
        }
      }
    }
    .pages {
      flex-wrap: wrap;
    }
    .pc-col {
      display: none;
    }
    .tb-hd,
    .tb-title,
    .tb-bd {
      .ant-col {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .header-wrap{
      display: block;
      padding-bottom: .625rem;
      .mb-menu{
        display: block;
        font-size: 20px;
      }
      .search-box{
        min-width: 100%;
      }
    }
    .header{
      align-items: center;
      justify-content: space-between;
      .logo-info{
        .name{
          font-size: 1rem;
        }
      }
      .menu{
        margin: 0;
        display: none;
      }
      .menu-side{
        display: block;
        border: 0 none;
      }
    }
  }
  .countries-wrap{
    width: 100%;
  }
  .map{
    width: 100%;
    height: auto;
  }
  .about{
    width: 90%;
  }
}
