.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 10px;
  .mb-menu{
    display: none;
  }
  .search-box {
    min-width: 220px;
    .ant-select {
      width: 100%;
    }
  }
}
.header {
  height: 50px;
  min-width: 0;
  flex: auto;
}
.logo-info {
  display: flex;
  align-items: center;
  color: #333;
  .logo {
    width: 40px;
  }
  .name {
    font-size: 20px;
    margin-left: 10px;
  }
}
.menu {
  margin-left: 20px;
  min-width: 0;
  flex: auto;
}
.footer {
  background-color: #121212;
  color: #fff;
  text-align: center;
  padding: 0.625rem 0;
  .ant-flex {
    color: #999;
    margin: 5px 0;
    a {
      color: #999;
    }
  }
}
